<template>
  <div style="width:580px; display: flex;">
    <div style="width:37px; padding-top: 4px;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="!!activeProject"
            v-on="on"
            small
            icon
            color="grey lighten-1"
            @click="getItems"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Reload Assigned Project List</span>
      </v-tooltip>
    </div>
    <div style="width:450px">
      <v-select
        single-line
        name="projectAssignmentDD"
        :loading="projectDD.loading"
        item-text="ProjectNM"
        item-value="ProjectID"
        @change="validate"
        v-model="projectDD.value"
        :disabled="!!activeProject"
        class="mr-3"
        :items="projectDD.items"
        label="Assigned Projects"
        hide-details
        :error-messages="projectDD.errMsg"
        no-data-text="You have no assignments"
        required
      ></v-select>
    </div>
    <v-btn
      outlined
      @click="startWork"
      v-if="!activeProject"
      :loading="loading"
      :disabled="!projectDD.value"
      >Start Work</v-btn
    >
    <v-btn raised @click="confirmWorkEnd" color="accent" v-if="activeProject" :loading="loading"
      >End Work</v-btn
    >
    <v-dialog v-model="lunchBreak.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Add lunch break</v-card-title>
        <v-card-text>
          <v-select
            v-model="lunchBreak.lunchHour"
            :items="lunchBreak.items"
            :error-messages="lunchBreak.errMsg"
            label="Lunch Break"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="lunchBreak.dialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="endWork" :loading="loading">End Work</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectDD: {
        loading: false,
        items: [],
        value: null,
        errMsg: null
      },
      loading: null,
      lunchBreak: {
        dialog: false,
        items: [
          { value: 0, text: "No lunch break" },
          { value: 0.5, text: "0.5 Hour" },
          { value: 1, text: "1 Hour" },
          { value: 1.5, text: "1.5 Hours" },
          { value: 2, text: "2 Hours" }
        ],
        lunchHour: 0,
        errMsg: null
      }
    };
  },
  methods: {
    validate() {
      if (this.projectDD.value > 0) this.projectDD.errMsg = "";
    },
    startWork() {
      if (this.projectDD.value > 0) {
        this.loading = true;
        this.$store
          .dispatch("ccms/startWork", this.projectDD.value)
          .then(() => {
            let toRedirect = ["Timesheet"];
            if (toRedirect.includes(this.$route.name)) {
              this.$router.push({ name: "Call List" });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.projectDD.errMsg = "Please select a project to work on.";
      }
    },
    confirmWorkEnd() {
      this.lunchBreak.dialog = true;
      this.lunchBreak.lunchHour = 0;
      this.lunchBreak.errMsg = null;
    },
    endWork() {
      this.loading = true;
      this.$store
        .dispatch("ccms/endWork", this.lunchBreak.lunchHour)
        .then((res) => {
          if (res.error) {
            this.lunchBreak.errMsg = res.error;
          } else {
            if (res == 0) {
              this.$store.commit("sendAlert", {
                msg: "Work session has already expired.",
                color: "error"
              });
            }

            this.projectDD.value = null;
            this.lunchBreak.dialog = false;
            if (this.$route.name != "Timesheet") {
              this.$router.push({ name: "Timesheet" });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getItems() {
      this.projectDD.loading = true;
      return this.$axios
        .get("/telemarketer/assignment")
        .then((res) => {
          this.projectDD.items = res.data;
          return res;
        })
        .finally(() => {
          this.projectDD.loading = false;
        });
    }
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  },
  created() {
    this.getItems().then(() => {
      this.loading = true;
      this.$axios
        .get("/telemarketer/timesheet/isworkstarted")
        .then((res) => {
          this.projectDD.value = res.data.ProjectID;
          this.$store.commit("ccms/setActiveProject", this.projectDD.value);
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }
};
</script>
