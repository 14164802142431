<template>
  <v-badge :content="badgeCount" :value="badgeCount" color="red" offset-y="23" offset-x="18">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="500"
      offset-x
      offset-y
      max-width="500"
      max-height="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon :disabled="!activeProject" @click="getSchedule">
          <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-subheader>
            Due and Today's Callback Schedules
            <v-spacer />
            <ActionButton icon="mdi-dots-vertical" tooltip="More" @action-click="ViewMore" />
          </v-subheader>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line, divider, list-item-two-line"
          ></v-skeleton-loader>
          <v-list-item-group v-else-if="items.length > 0">
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index"
                @click="onItemClick(item)"
                :class="item.relative_time == 'due' ? 'blue lighten-5' : ''"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.ProjectNM"
                    class="font-weight-bold"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-text="item.object_label"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="item.scheduled_time"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click.stop="deleteSchedule(item.id)">
                    <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="index + 1 < items.length" :key="`A${index}`"></v-divider>
            </template>
          </v-list-item-group>
          <v-list-item-group v-else>
            <v-list-item :disabled="true">
              <v-list-item-content>
                <v-list-item-subtitle class="text--disabled"
                  >You have no callback schedule for today</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="menu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-badge>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    ActionButton
  },
  data() {
    return {
      menu: false,
      items: [],
      loading: false,
      timer: null
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    },
    badgeCount() {
      return this.$store.state.ccms.scheduleCnt > 0 && this.activeProject
        ? this.$store.state.ccms.scheduleCnt
        : null;
    }
  },
  watch: {
    activeProject: {
      immediate: true,
      handler() {
        this.checkSchedule(true);
      }
    }
  },
  methods: {
    ViewMore() {
      if (this.$route.name != "Schedule Call Back List") {
        this.$router.push({ name: "Schedule Call Back List" });
      }
      this.menu = false;
    },
    onItemClick(e) {
      this.openTMUI(e.COMPANY_OBJECT_ID, e.OBJECT_ID, { ccmsId: e.ccmsId });
    },
    getSchedule() {
      this.loading = true;
      this.$axios
        .get("/telemarketer/schedule/now", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          this.items = res.data;
          this.$store.dispatch(
            "ccms/checkSchedule",
            this.items.filter((e) => e.relative_time == "due").length
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSchedule(id) {
      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/telemarketer/schedule/delete", {
              params: {
                id: id
              }
            })
            .then((res) => {
              if (!res.data.errorMsg) {
                this.$store.commit("sendAlert", {
                  msg: "Deleted",
                  color: "success"
                });
                this.deleteFromList(id);
                this.$store.dispatch("ccms/checkSchedule");
              } else {
                this.$store.commit("sendAlert", {
                  msg: res.data.errorMsg,
                  color: "error"
                });
              }
            });
        }
      });
    },
    checkSchedule(skipIntervalCheck) {
      // only call api per 15mins interval
      if ((new Date().getMinutes() % 5 === 0 || skipIntervalCheck) && this.activeProject) {
        this.$store
          .dispatch("ccms/checkSchedule")
          .then(() => {
            this.setTimer();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.setTimer();
      }
    },
    setTimer() {
      let self = this;
      self.timer = setTimeout(function() {
        self.checkSchedule();
      }, 1000 * 60);
    }
  },
  destroyed() {
    if (this.timer) clearTimeout(this.timer);
  }
};
</script>
