<template>
  <v-badge :content="badgeCount" :value="badgeCount" color="red" offset-y="23" offset-x="18">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="500"
      offset-x
      offset-y
      max-width="500"
      max-height="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="openMessageHistory">
          <v-icon>mdi-message-text</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-subheader>
            Latest Messages
            <v-spacer />
            <ActionButton icon="mdi-dots-vertical" tooltip="More" @action-click="ViewMore" />
          </v-subheader>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line, divider, list-item-two-line"
          ></v-skeleton-loader>
          <v-list-item-group v-else-if="items.length > 0">
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index"
                @click="showFullMessage(index)"
                :class="item.read_flag > 0 ? '' : 'blue lighten-5'"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.AdminNM"
                    class="font-weight-bold"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-html="item.Messages"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text v-text="item.relativeTime"></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="index + 1 < items.length" :key="`A${index}`"></v-divider>
            </template>
          </v-list-item-group>
          <v-list-item-group v-else>
            <v-list-item :disabled="true">
              <v-list-item-content>
                <v-list-item-subtitle class="text--disabled"
                  >You have no new messages</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog @keydown.esc="messageDialog = false" v-model="messageDialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ activeMsg.AdminNM }}
          <v-spacer />
          <span class="overline text--secondary">{{ activeMsg.ADD_DATE }}</span>
        </v-card-title>
        <v-card-text v-html="activeMsg.Messages"></v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="messageDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-badge>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    ActionButton
  },
  data() {
    return {
      activeMsg: {
        Messages: null,
        AdminNM: null,
        ADD_DATE: null
      },
      loading: true,
      messageDialog: false,
      menu: false,
      badgeCount: 0,
      items: [],
      timer: null
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  },
  methods: {
    ViewMore() {
      if (this.$route.name != "Message History") {
        this.$router.push({ name: "Message History" });
      }
      this.menu = false;
    },
    openMessageHistory() {
      this.loading = true;
      this.$axios
        .get("/telemarketer/message/unread", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          this.items = res.data;
          this.badgeCount = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showFullMessage(index) {
      this.activeMsg = this.items[index];
      this.messageDialog = true;
    },
    setTimer() {
      let self = this;
      self.timer = setTimeout(function() {
        self.checkMessage();
      }, 1000 * 60);
    },
    checkMessage() {
      this.$axios
        .get("/telemarketer/message/check", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          this.badgeCount = res.data.count > 0 ? res.data.count : null;
          this.setTimer();
        });
    }
  },
  mounted() {
    this.checkMessage();
  },
  destroyed() {
    if (this.timer) clearTimeout(this.timer);
  }
};
</script>
